import { IconCreditCard01 } from '@troon/icons';
import { CreditCardBrand } from '../graphql';
import { Amex, Discover, Mastercard, Visa } from '../components/credit-cards';
import type { Component } from 'solid-js';

export const cardBrandToString: Record<CreditCardBrand, string> = {
	[CreditCardBrand.Amex]: 'Amex',
	[CreditCardBrand.Discover]: 'Discover',
	[CreditCardBrand.Mastercard]: 'Mastercard',
	[CreditCardBrand.Other]: 'Other',
	[CreditCardBrand.Visa]: 'Visa',
};
export const cardBrandToComponent: Record<CreditCardBrand, Component> = {
	[CreditCardBrand.Amex]: Amex,
	[CreditCardBrand.Discover]: Discover,
	[CreditCardBrand.Mastercard]: Mastercard,
	[CreditCardBrand.Other]: function () {
		return <IconCreditCard01 />;
	},
	[CreditCardBrand.Visa]: Visa,
};
